import React, { useState } from "react"

const defaultState = {
    indicationModalViewed: false,
    modalOpen: false,
    modalExitOpen: false,
    modalHCPOpen: false,
    externalUrl: null,
    disableIndicationModal: () => {},
    showModal: () => {},
    showExitModal: () => {},
    showHCPModal: () => {},
    setExternalUrl: () => {},
    modalTrigger: null,
    setModalTrigger: () => {},
    menuOpen: false,
    showMenu: () => {},
    device: 'desktop' ,
    setDevice: () => {},
    isiThreshold: 164,
    setISIThreshold: () => {}
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [indicationModalViewed, disableIndicationModal] = useState(defaultState.indicationModalViewed)
    const [modalOpen, showModal] = useState(defaultState.modalOpen)
    const [modalExitOpen, showExitModal] = useState(defaultState.modalExitOpen)
    const [modalHCPOpen, showHCPModal] = useState(defaultState.modalHCPOpen)
    const [url, setUrl] = useState(defaultState.url)
    const [menuOpen, showMenu] = useState(defaultState.menuOpen)
    const [modalTrigger, setModalTrigger] = useState(defaultState.modalTrigger)
    const [device, setDevice] = useState(defaultState.device)
    const [isiThreshold, setISIThreshold] = useState(defaultState.isiThreshold)


    const store = {
        indicationModalViewed: indicationModalViewed,
        disableIndicationModal: disableIndicationModal,
        modalOpen: modalOpen,
        modalExitOpen: modalExitOpen,
        modalHCPOpen: modalHCPOpen,
        showModal: showModal,
        showExitModal: showExitModal,
        showHCPModal: showHCPModal,
        externalUrl: url,
        setExternalUrl: setUrl,
        modalTrigger: modalTrigger,
        setModalTrigger: setModalTrigger,
        menuOpen: menuOpen,
        showMenu: showMenu,
        device: device,
        setDevice: setDevice,
        isiThreshold: isiThreshold,
        setISIThreshold: setISIThreshold
    }

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
